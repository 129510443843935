import React from "react"
import Layout from "../components/Layout.js"
import PostList from "../components/posts/post-list.js"
import { graphql } from "gatsby"
import ThemeProvider from "../components/ThemeProvider.js"
import { ThemeContext } from "../components/ThemeProvider.js"
import WelcomeHeader from "../components/WelcomeHeader/welcome-header"
import PostCode from "../components/posts/post-code.js"
import SEO from "../components/SEO.js"
import NewsletterForm from "../components/NewsletterForm"

var { useEffect, useContext } = React

var { useState } = React

const Index = props => {
  console.log(props.data)
  var {
    allMdx: { edges },
  } = props.data
  return (
    <ThemeProvider>
      <Layout theme="light">
        <SEO title="Design" />
        <WelcomeHeader />
        <PostList posts={edges} />
        <NewsletterForm />
      </Layout>
    </ThemeProvider>
  )
}

export default Index

export const getPosts = graphql`
  query {
    allMdx(
      filter: { frontmatter: { category: { eq: "design" } } }
      sort: { fields: frontmatter___pubId, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            category
            published
            project
          }
          body
        }
      }
    }
  }
`

// coverImage {
//   childImageSharp {
//     fluid {
//       ...GatsbyImageSharpFluid_withWebp
//     }
//   }
// }
